import { GroupuiGridRow, GroupuiGridCol, GroupuiAccordion, GroupuiHeadline } from '@sdc-wob-type-3/group-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CisLogo from '../../assets/CisLogo';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { GET_LOGIN_LINK_PATH } from '../landing-page/LandingPage';
import './FAQ.css';

const FAQ = () => {
  const { t } = useTranslation();

  const mailTo = () => {
    window.open(`mailto:B2Bteam@vwgroupsupply.com?subject=CIS-Support:`, '_self');
  };

  return (
    <div id="faq-global-container">
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header />
        </GroupuiGridCol>
      </GroupuiGridRow>

      <div id="faq-content-container">
        <div id="faq-content-container2">
          <div id="logo-faq-container">
            <CisLogo id="cis-logo-body" />
            <div id="faq-title-container">
              <div id="faq-title">
                <GroupuiHeadline heading="h1" weight="thin">
                  {t('page.faq.title')}
                </GroupuiHeadline>
              </div>
              <div id="faq-title">
                <GroupuiHeadline heading="h4" weight="thin">
                  {t('page.faq.description')}
                </GroupuiHeadline>
              </div>
            </div>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:1.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:1.description')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:2.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:2.description')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:3.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:3.description')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:4.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:4.description')}</span>
              <Link to={`${GET_LOGIN_LINK_PATH}`} className="faq-link" data-testid="faq-link-1">
                {t('faq:4.linkText')}
              </Link>
              <span className="faq-accordion-container">{t('faq:4.description2')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:5.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:5.description')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:6.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:6.description')}</span>
              <Link to="#" className="faq-link" onClick={mailTo} data-testid="faq-mailto-1">
                {t('faq:6.mailtoText')}
              </Link>
              <span className="faq-accordion-container">{t('faq:6.description2')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:7.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:7.description')}</span>
              <Link to={`${GET_LOGIN_LINK_PATH}`} className="faq-link" data-testid="faq-link-2">
                {t('faq:7.linkText')}
              </Link>
              <span className="faq-accordion-container">{t('faq:7.description2')}</span>
              <Link to="#" className="faq-link" onClick={mailTo} data-testid="faq-mailto-2">
                {t('faq:7.mailtoText')}
              </Link>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:8.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:8.description')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:9.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:9.description')}</span>
            </GroupuiAccordion>
          </div>

          <div className="faq-accordion-container">
            <GroupuiAccordion>
              <span slot="headline">
                <GroupuiHeadline heading="h6">{t('faq:10.title')}</GroupuiHeadline>
              </span>
              <span className="faq-accordion-container">{t('faq:10.description')}</span>
            </GroupuiAccordion>
          </div>
        </div>
      </div>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <Footer />
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
      </GroupuiGridRow>
    </div>
  );
};

export default FAQ;
