import { Location } from 'history';

export const getPathParams = (useParamsResult: Record<string, any>) => {
  const result: Record<string, any> = {};

  Object.keys(useParamsResult).forEach(param => {
    result[param] = decodeURIComponent(useParamsResult[param]);
  });
  return result;
};

export const createParamUrl = (path: string, ...params: UrlParam[]) => {
  params.forEach(param => {
    path = path.replace(`:${param.name}`, encodeURIComponent(param.value));
  });
  return path;
};

export const getQueryParams = (useLocationResult: Location) => {
  const result: Record<string, any> = {};

  new URLSearchParams(useLocationResult.search).forEach((paramValue, paramName) => {
    result[paramName] = decodeURIComponent(paramValue);
  });
  return result;
};

export const createQueryParams = (...params: UrlParam[]) => {
  const queryParams = new URLSearchParams();
  params.forEach(param => {
    queryParams.append(param.name, encodeURIComponent(param.value));
  });
  return { search: queryParams.toString() };
};

type UrlParam = {
  name: string;
  value: string;
};

export const urlParam = (name: string, value: string | number) => ({ name, value } as UrlParam);
