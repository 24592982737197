import axios from 'axios';
import environment from '../../environment';

export interface ILoginLinkService {
  request(email: string): Promise<string>;
}

export class LoginLinkService implements ILoginLinkService {
  request(mail: string): Promise<string> {
    const encodedMail = encodeURIComponent(mail);
    return axios({
      method: 'get',
      url: `${environment.PUBLIC_BACKEND_URL}/v1/login?email=${encodedMail}`,
    }).then(result => {
      return result.data as string;
    });
  }
}
