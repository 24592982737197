import { LANGUAGES } from '../assets/LanguageCodes';

export const validate = (code: string): boolean => {
  return LANGUAGES.hasOwnProperty(code);
};

export const getCode = (name: string): string => {
  const code = Object.keys(LANGUAGES).find(c => {
    // @ts-ignore
    const language = LANGUAGES[c];

    return (
      language.name.toLowerCase() === name.toLowerCase() || language.nativeName.toLowerCase() === name.toLowerCase()
    );
  });
  return code || '';
};

export const getAllNames = (): string[] => {
  return Object.values(LANGUAGES).map(l => l.name);
};
