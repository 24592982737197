import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import '@sdc-wob-type-3/group-ui/dist/group-ui/assets/themes/vwag/vwag.css';
import { applyPolyfills, defineCustomElements } from '@sdc-wob-type-3/group-ui/dist/loader';
import { BrowserRouter } from 'react-router-dom';
import './i18n/i18n.ts';

applyPolyfills()
  .then(() => {
    return defineCustomElements();
  })
  .catch(() => {});

const rootElement = document.getElementById('root');
render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);
