import * as React from 'react';

function CisLogoHeader(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={48} height={48} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.303 14.548a3.549 3.549 0 007.096 0 3.548 3.548 0 00-7.096 0m1.808 0a1.74 1.74 0 113.48 0 1.739 1.739 0 11-3.48 0m10.805 2.968a2.756 2.756 0 105.512 0 2.757 2.757 0 00-5.512 0m1.687 0a1.069 1.069 0 012.137 0 1.069 1.069 0 01-2.137 0m-25.33 0a2.757 2.757 0 005.513 0 2.756 2.756 0 10-5.513 0m1.688 0a1.069 1.069 0 012.137 0 1.068 1.068 0 11-2.137 0m11.393 1.81c-1.624 0-3.28.667-3.984 2.224-.27.59-3.087 6.735-4.467 9.782-.375.836-.908 1.399-1.884 1.421-1.136-.027-1.89-.934-2.02-2.014-.118-.816-.45-3.077-.518-3.546-.04-.71-.605-.852-1.183-.852.37-.845.736-1.695 1.163-2.512.3-.574.84-.735 1.454-.735h2.231c.463 0 .924.103 1.243.46a.903.903 0 001.35-1.2h-.001c-.66-.74-1.617-1.068-2.592-1.068h-2.231c-1.297 0-2.434.531-3.05 1.71-.66 1.262-1.176 2.58-1.778 3.868-.289.618.181 1.285.832 1.285h.88l.412 2.838c.28 1.961 1.725 3.55 3.78 3.565 1.722.012 2.884-.998 3.568-2.5 1.373-3.03 4.185-9.178 4.414-9.68.434-.943 1.4-1.238 2.365-1.238h3.025c.966 0 1.931.295 2.365 1.238.23.502 3.042 6.65 4.415 9.68.683 1.502 1.846 2.512 3.568 2.5 2.054-.015 3.499-1.604 3.78-3.565l.411-2.838h.88c.65 0 1.121-.667.833-1.285-.603-1.287-1.118-2.606-1.778-3.869-.616-1.178-1.754-1.71-3.05-1.71h-2.232c-.975 0-1.932.33-2.591 1.07a.903.903 0 101.35 1.2h-.001c.319-.358.78-.461 1.242-.461h2.232c.614 0 1.153.161 1.453.735.428.817.794 1.667 1.164 2.512-.578 0-1.143.141-1.183.852l-.519 3.546c-.13 1.08-.883 1.987-2.02 2.014-.976-.022-1.508-.585-1.884-1.421-1.38-3.047-4.197-9.192-4.467-9.782-.703-1.557-2.36-2.224-3.983-2.224h-2.994zm5.1 9.71L27 32.138c-.235 1.62-1.4 3.078-3.15 3.06-1.75.018-2.916-1.44-3.151-3.06l-.452-3.102a.903.903 0 00-1.787.254l.452 3.102C19.28 34.93 21.182 37 23.85 37c2.669 0 4.57-2.07 4.938-4.608l.451-3.102a.903.903 0 00-.766-1.02.903.903 0 00-1.02.767"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default CisLogoHeader;
