import axios from 'axios';

export const IMPRINT_SERVICE_URL = 'https://consent.vwgroup.io/consent/v1/texts/consent/de';

export interface IImprintService {
  getHTML(lang: string): Promise<string>;
}

export class ImprintService implements IImprintService {
  getHTML(lang: string): Promise<string> {
    return axios.get(`${IMPRINT_SERVICE_URL}/${lang}/volkswagen-imprint/latest/html`).then(result => {
      return result.data;
    });
  }
}
