import axios from 'axios';
import { Contact } from './Contact';
import environment from '../../environment';

export class ContactService {
  findByLoginToken(token: string): Promise<Contact> {
    return axios.get(`${environment.PUBLIC_BACKEND_URL}/v1/contacts/${token}`).then(result => {
      return result.data as Contact;
    });
  }

  consent(token: string, contact: Contact): Promise<any> {
    return axios({
      method: 'post',
      url: `${environment.PUBLIC_BACKEND_URL}/v1/contacts/${token}`,
      data: {
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phoneNumber: contact.phoneNumber,
        alternativePhoneNumber: contact.alternativePhoneNumber,
        supportedLanguages: contact.supportedLanguages,
        comment: contact.comment,
      },
    }).then(result => new Promise(resolve => resolve(result.data)));
  }

  reject(token: string, contact: Contact): Promise<any> {
    return axios({
      method: 'post',
      url: `${environment.PUBLIC_BACKEND_URL}/v1/contacts/${token}/rejection`,
      data: {
        email: contact.email,
      },
    }).then(result => new Promise(resolve => resolve(result.data)));
  }
}
