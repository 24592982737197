import { GroupuiGridCol, GroupuiGridRow, GroupuiHeadline, GroupuiText } from '@sdc-wob-type-3/group-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './TermsOfUse.css';
import CisLogo from '../../assets/CisLogo';
import { GET_LOGIN } from '../getlogin-page/GetLoginPage';

export const TERMS_OF_USE = '/terms-of-use';
const URS_URL = 'https://wings-ui.apps.emea.vwapps.io/appstore';

const TermsOfUse = () => {
  const { t } = useTranslation('termsOfUse');

  const headlineWithText = (section: string) => {
    return (
      <React.Fragment>
        <GroupuiText>{t(`${section}.header`)}</GroupuiText>
        <GroupuiText>{t(`${section}.content`)}</GroupuiText>
        <br />
      </React.Fragment>
    );
  };

  const headlineWithTextAndLink = (section: string, url: string, linkName: string) => {
    return (
      <React.Fragment>
        <GroupuiText>{t(`${section}.header`)}</GroupuiText>
        <GroupuiText>
          {t(`${section}.content-1`)}{' '}
          <a href={url} style={{ color: 'white' }}>
            {t(linkName)}
          </a>{' '}
          {t(`${section}.content-2`)}{' '}
        </GroupuiText>
        <br />
      </React.Fragment>
    );
  };

  return (
    <div id="page">
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header />
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <div id="content">
            <CisLogo id="terms-of-use-cis-logo" />
            <GroupuiHeadline heading="h3">Contact Information Service</GroupuiHeadline>

            <br />

            <GroupuiHeadline heading="h3">{t('header')}</GroupuiHeadline>
            <br />

            <GroupuiText>{t('intro')}</GroupuiText>
            <br />

            {headlineWithText('contentAndAvailability')}
            {headlineWithText('propertyRights')}
            {headlineWithText('improperUse')}

            {headlineWithTextAndLink('europeanEmployees', URS_URL, 'europeanEmployees.urs')}

            {headlineWithTextAndLink('externalProvider', GET_LOGIN, 'externalProvider.link')}

            {headlineWithText('purpose')}
            {headlineWithText('responsibility')}
            {headlineWithText('finalProvisions')}

            <GroupuiText>{t('release')}</GroupuiText>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <Footer />
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>
    </div>
  );
};

export default TermsOfUse;
