import { GroupuiGridCol, GroupuiGridRow, GroupuiHeadline, GroupuiText } from '@sdc-wob-type-3/group-ui-react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './PrivacyPolicy.css';

export const PRIVACY_POLICY = '/privacy';

const PrivacyPolicy = () => {
  const { t } = useTranslation('privacy');
  const numberOfRighTexts: string[] = ['1', '2', '3', '4', '5', '6', '7', '8'];

  const rightsText = (itemNr: string) => {
    if (itemNr === '5' || itemNr === '7')
      return (
        <Fragment key={`rights.subItems.subitem-${itemNr}`}>
          <div style={{ border: '1px solid', padding: 5 }}>
            <GroupuiText>
              <span style={{ fontWeight: 'bold' }}>{t(`rights.subItems.subitem-${itemNr}.header`)}</span>
              {t(`rights.subItems.subitem-${itemNr}.content`)}
            </GroupuiText>
          </div>
          <br />
        </Fragment>
      );
    return (
      <Fragment key={`rights.subItems.subitem-${itemNr}`}>
        <GroupuiText>
          <span style={{ fontWeight: 'bold' }}>{t(`rights.subItems.subitem-${itemNr}.header`)}</span>
          {t(`rights.subItems.subitem-${itemNr}.content`)}
        </GroupuiText>
        <br />
      </Fragment>
    );
  };

  return (
    <div id="page">
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header />
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <div id="content">
            <GroupuiHeadline heading="h3">{t('header')}</GroupuiHeadline>

            <br />

            <GroupuiHeadline heading="h6">{t('responsible.header')}</GroupuiHeadline>

            <GroupuiText>
              {t('responsible.content')}{' '}
              <a href="mailto:inbound.logistics.support.vwag.r.wob@volkswagen.de" style={{ color: 'white' }}>
                {t('responsible.email')}
              </a>
              {t('responsible.contentEnd')}
            </GroupuiText>

            <br />

            <GroupuiHeadline heading="h6">{t('processing.header')}</GroupuiHeadline>

            <GroupuiHeadline heading="h6">{t('processing.subHeader')} </GroupuiHeadline>

            <GroupuiText>{t('processing.content')}</GroupuiText>

            <br />

            <GroupuiText>{t('processing.subItems.subitemOne.content')}</GroupuiText>

            <ul>
              <li>
                <GroupuiText>{t('processing.subItems.subitemOne.subItems.subitemOne')}</GroupuiText>
              </li>
              <li>
                <GroupuiText>{t('processing.subItems.subitemOne.subItems.subitemTwo.content')}</GroupuiText>
                <ul>
                  <li>
                    <GroupuiText>
                      {t('processing.subItems.subitemOne.subItems.subitemTwo.subItems.subitemOne')}
                    </GroupuiText>
                  </li>
                  <li>
                    <GroupuiText>
                      {t('processing.subItems.subitemOne.subItems.subitemTwo.subItems.subitemTwo')}
                    </GroupuiText>
                  </li>
                  <li>
                    <GroupuiText>
                      {t('processing.subItems.subitemOne.subItems.subitemTwo.subItems.subitemThree')}
                    </GroupuiText>
                  </li>
                  <li>
                    <GroupuiText>
                      {t('processing.subItems.subitemOne.subItems.subitemTwo.subItems.subitemFour')}
                    </GroupuiText>
                  </li>
                </ul>
              </li>
            </ul>

            <GroupuiText>{t('processing.subItems.subitemTwo.content')}</GroupuiText>

            <br />

            <GroupuiText>{t('processing.subItems.subitemTwo.subItems.subitemOne')}</GroupuiText>

            <br />

            <GroupuiText>{t('processing.subItems.subitemTwo.subItems.subitemTwo')}</GroupuiText>

            <br />

            <GroupuiText>{t('processing.subItems.subitemThree.content')}</GroupuiText>

            <br />

            <GroupuiText>{t('processing.subItems.subitemThree.subItems.subitemOne')}</GroupuiText>

            <br />

            <GroupuiText>{t('processing.subItems.subitemFour.content')}</GroupuiText>

            <br />

            <GroupuiText>{t('processing.subItems.subitemFour.subItems.subitemOne.content')}</GroupuiText>

            <ul>
              <li>
                <GroupuiText>
                  {t('processing.subItems.subitemFour.subItems.subitemOne.subItems.subitemOne')}
                </GroupuiText>
              </li>
              <li>
                <GroupuiText>
                  {t('processing.subItems.subitemFour.subItems.subitemOne.subItems.subitemTwo')}
                </GroupuiText>
              </li>
              <li>
                <GroupuiText>
                  {t('processing.subItems.subitemFour.subItems.subitemOne.subItems.subitemThree')}
                  <a
                    href={t('processing.subItems.subitemFour.subItems.subitemOne.subItems.subitemThreeLink')}
                    style={{ color: 'white' }}
                  >
                    {t('processing.subItems.subitemFour.subItems.subitemOne.subItems.subitemThreeLink')}
                  </a>{' '}
                  {t('processing.subItems.subitemFour.subItems.subitemOne.subItems.subitemThreeEnd')}
                </GroupuiText>
              </li>
              <li>
                <GroupuiText>
                  {t('processing.subItems.subitemFour.subItems.subitemOne.subItems.subitemFour')}
                </GroupuiText>
              </li>
            </ul>
            <GroupuiText>{t('processing.subItems.subitemFour.subItems.subitemOne.footerContent')}</GroupuiText>
            <br />
            <GroupuiText>{t('processing.subItems.subitemFour.subItems.subitemTwo.content')}</GroupuiText>

            <ul>
              <li>
                <GroupuiText>
                  {t('processing.subItems.subitemFour.subItems.subitemTwo.subItems.subitemOne')}
                </GroupuiText>
              </li>
              <li>
                <GroupuiText>
                  {t('processing.subItems.subitemFour.subItems.subitemTwo.subItems.subitemTwo')}
                </GroupuiText>
              </li>
            </ul>

            <GroupuiHeadline heading="h6">{t('legalBasis.header')}</GroupuiHeadline>

            <GroupuiText>{t('legalBasis.content')}</GroupuiText>

            <br />

            <GroupuiHeadline heading="h6">{t('storage.header')}</GroupuiHeadline>

            <GroupuiText>{t('storage.content')}</GroupuiText>

            <br />

            <table style={{ textAlign: 'left', border: '1px solid', borderCollapse: 'collapse' }}>
              <tbody>
                <tr style={{ border: '1px solid' }}>
                  <th style={{ border: '1px solid', padding: 5 }}>{t('storage.table.headerOne')}</th>
                  <th style={{ border: '1px solid', padding: 5 }}>{t('storage.table.headerTwo')}</th>
                </tr>
                <tr>
                  <td style={{ border: '1px solid', padding: 5 }}>{t('storage.table.rowOneColumnOne')}</td>
                  <td style={{ border: '1px solid', padding: 5 }}>{t('storage.table.rowOneColumnTwo')}</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid', padding: 5 }}>{t('storage.table.rowTwoColumnOne')}</td>
                  <td style={{ border: '1px solid', padding: 5 }}>{t('storage.table.rowTwoColumnTwo')}</td>
                </tr>
              </tbody>
            </table>

            <br />

            <GroupuiHeadline heading="h6">{t('rights.header')}</GroupuiHeadline>

            <GroupuiText>{t('rights.content')}</GroupuiText>

            <br />

            {numberOfRighTexts.map(rightsNumber => {
              return rightsText(rightsNumber);
            })}

            <br />

            <GroupuiHeadline heading="h6">{t('contactPerson.header')}</GroupuiHeadline>

            <GroupuiHeadline heading="h6">{t('contactPerson.subItems.subitemOne.header')}</GroupuiHeadline>

            <GroupuiText>
              {t('contactPerson.subItems.subitemOne.content')}
              <a href={t('contactPerson.subItems.subitemOne.link')} style={{ color: 'white' }}>
                {t('contactPerson.subItems.subitemOne.link')}
              </a>
              .
            </GroupuiText>
            <GroupuiText>
              {t('contactPerson.subItems.subitemOne.contentTwo')}
              <a href={t('contactPerson.subItems.subitemOne.linkTwo')} style={{ color: 'white' }}>
                {t('contactPerson.subItems.subitemOne.linkTwo')}
              </a>
              .
            </GroupuiText>

            <br />

            <GroupuiHeadline heading="h6">{t('contactPerson.subItems.subitemTwo.header')}</GroupuiHeadline>
            <GroupuiText>{t('contactPerson.subItems.subitemTwo.contentOne')}</GroupuiText>

            <br />

            <GroupuiText>{t('contactPerson.subItems.subitemTwo.contentTwo')}</GroupuiText>
            <GroupuiText>{t('contactPerson.subItems.subitemTwo.contentThree')}</GroupuiText>
            <GroupuiText>
              <a href={'mailto:' + t('contactPerson.subItems.subitemTwo.email')} style={{ color: 'white' }}>
                {t('contactPerson.subItems.subitemTwo.email')}
              </a>
            </GroupuiText>

            <br />

            <GroupuiText>{t('release')}</GroupuiText>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <Footer />
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
      </GroupuiGridRow>
    </div>
  );
};

export default PrivacyPolicy;
