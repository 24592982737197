import {
  GroupuiGridCol,
  GroupuiGridRow,
  GroupuiInput,
  GroupuiMultiselect,
  GroupuiMultiselectOption,
  GroupuiTextarea,
} from '@sdc-wob-type-3/group-ui-react';
import React, { FormEvent, Fragment, useEffect, useState } from 'react';
import { Contact } from '../Contact';
import { useTranslation } from 'react-i18next';
import './BasicData.css';
import { getAllNames, getCode } from '../../../utils/LanguageUtils';

type BasicDataProps = {
  validContact: Contact;
  isDirty: any;
  isEditable: boolean;
  isValid: any;
};

const BasicData = (props: BasicDataProps) => {
  const [firstName, setFirstName] = useState<string>('');
  const [isFirstNameValid, setFirstNameValid] = useState<boolean>(true);
  const [lastName, setLastName] = useState<string>('');
  const [isLastNameValid, setLastNameValid] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setEmailValid] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isPhoneNumberValid, setPhoneNumberValid] = useState<boolean>(true);
  const [alternativePhoneNumber, setAlternativePhoneNumber] = useState<string>('');
  const [isAlternativePhoneNumberValid, setAlternativePhoneNumberValid] = useState<boolean>(true);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [isCommentValid, setCommentValidValid] = useState<boolean>(true);

  const [languageInit, setLanguageInit] = useState<string>('');

  const { t } = useTranslation();

  const namePattern = '^(?!\\s*$)[\\D]{2,32}$';
  const emailPattern = '^(?=^[a-zA-Z0-9\\-_.@]{8,64}$)[a-zA-Z0-9\\-_.]+@[a-zA-Z0-9\\-.]+[.]{1}[a-zA-Z]{2,}$';
  const phoneNumberPattern = '^(?=^[0-9\\+\\s\\-]{7,18}$)(00|\\+)[0-9\\s\\-]+$';

  useEffect(() => {
    handleFirstName(props.validContact.firstName);
    handleLastName(props.validContact.lastName);
    handleEmail(props.validContact.email);
    handlePhoneNumber(props.validContact.phoneNumber);
    handleAlternativePhoneNumber(props.validContact.alternativePhoneNumber);
    initLanguages(props.validContact.supportedLanguages);
    handleComment(props.validContact.comment);
    props.isDirty(false);
  }, []);

  const handleLanguage = (event: any) => {
    if (event.detail) {
      var languages = event.detail.selectedOptions.map((it: any) => it.value);
      setSelectedLanguages(languages);
    }
    props.isDirty(true);
    props.validContact.supportedLanguages = languages.join(';');
    setValid(
      isFirstNameValid && isLastNameValid && isPhoneNumberValid && isAlternativePhoneNumberValid && isCommentValid
    );
  };

  const initLanguages = (languages: string) => {
    if (languages) {
      setSelectedLanguages(languages.split(';'));
      setLanguageInit(languages);
    }
  };

  const setValid = (value: boolean) => {
    props.isValid(value);
  };

  const handleFirstName = (input: string) => {
    setFirstName(input);
    let valid = new RegExp(namePattern).test(input) && input.length >= 2;
    setFirstNameValid(valid);
    props.validContact.firstName = input;
    props.isDirty(true);
    setValid(valid && isLastNameValid && isPhoneNumberValid && isAlternativePhoneNumberValid && isCommentValid);
  };

  const handleLastName = (input: string) => {
    setLastName(input);
    let valid = new RegExp(namePattern).test(input) && input.length >= 2;
    setLastNameValid(valid);
    props.validContact.lastName = input;
    props.isDirty(true);
    setValid(isFirstNameValid && valid && isPhoneNumberValid && isAlternativePhoneNumberValid && isCommentValid);
  };

  const handleEmail = (input: string) => {
    setEmail(input);
    setEmailValid(new RegExp(emailPattern).test(input));
    props.isDirty(true);
  };

  const handlePhoneNumber = (input: string) => {
    setPhoneNumber(input);
    let valid = new RegExp(phoneNumberPattern).test(input);
    setPhoneNumberValid(valid);
    props.validContact.phoneNumber = input;
    props.isDirty(true);
    setValid(isFirstNameValid && isLastNameValid && valid && isAlternativePhoneNumberValid && isCommentValid);
  };

  const handleAlternativePhoneNumber = (input: string) => {
    setAlternativePhoneNumber(input);
    let valid = !input || input === '' || new RegExp(phoneNumberPattern).test(input);
    setAlternativePhoneNumberValid(valid);
    props.validContact.alternativePhoneNumber = input;
    props.isDirty(true);
    setValid(isFirstNameValid && isLastNameValid && isPhoneNumberValid && valid && isCommentValid);
  };
  const handleComment = (input: string) => {
    let valid = input.length < 500;
    setCommentValidValid(valid);
    setComment(input);
    props.validContact.comment = input;
    props.isDirty(true);
    setValid(isFirstNameValid && isLastNameValid && isPhoneNumberValid && isAlternativePhoneNumberValid && valid);
  };

  return (
    <Fragment>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="10" l="8" xl="6" xxl="4">
          <div className="margin-bottom-25">
            <GroupuiInput
              placeholder={t('page.contact.email-placeholder')}
              data-testid="input-email"
              maxlength={200}
              readonly={true}
              required={true}
              value={email}
              pattern={emailPattern}
              severity={isEmailValid ? 'none' : 'danger'}
              onInput={(ev: FormEvent) => handleEmail((ev.target as HTMLInputElement).value)}
            >
              <span slot="label">{t('page.contact.email-label')}</span>

              {!isEmailValid && <span slot="description">{t('page.contact.email-validation-error')}</span>}
            </GroupuiInput>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="10" l="6" xl="4" xxl="3">
          <div className="margin-bottom-25">
            <GroupuiInput
              class="basic-data-input"
              placeholder={t('page.contact.firstName-placeholder')}
              data-testid="input-firstName"
              maxlength={200}
              readonly={!props.isEditable}
              required={true}
              value={firstName}
              pattern={namePattern}
              severity={isFirstNameValid ? 'none' : 'danger'}
              onInput={(ev: FormEvent) => handleFirstName((ev.target as HTMLInputElement).value)}
            >
              <span slot="label">{t('page.contact.firstName-label')}</span>
              <span slot="description">{t('page.contact.name-validation-error')}</span>
            </GroupuiInput>
          </div>
        </GroupuiGridCol>

        <GroupuiGridCol s="12" m="10" l="6" xl="4" xxl="3">
          <div className="margin-bottom-25">
            <GroupuiInput
              placeholder={t('page.contact.lastName-placeholder')}
              data-testid="input-lastName"
              maxlength={200}
              readonly={!props.isEditable}
              required={true}
              value={lastName}
              pattern={namePattern}
              severity={isLastNameValid ? 'none' : 'danger'}
              onInput={(ev: FormEvent) => handleLastName((ev.target as HTMLInputElement).value)}
            >
              <span slot="label">{t('page.contact.lastName-label')}</span>
              <span slot="description">{t('page.contact.name-validation-error')}</span>
            </GroupuiInput>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="10" l="6" xl="4" xxl="3">
          <div className="margin-bottom-25">
            <GroupuiInput
              placeholder={t('page.contact.phoneNumber-placeholder')}
              data-testid="input-phoneNumber"
              maxlength={200}
              readonly={!props.isEditable}
              required={true}
              value={phoneNumber}
              pattern={phoneNumberPattern}
              severity={isPhoneNumberValid ? 'none' : 'danger'}
              onInput={(ev: FormEvent) => handlePhoneNumber((ev.target as HTMLInputElement).value)}
            >
              <span slot="label">{t('page.contact.phoneNumber-label')}</span>
              <span slot="description">{t('page.contact.phoneNumber-validation-error')}</span>
            </GroupuiInput>
          </div>
        </GroupuiGridCol>

        <GroupuiGridCol s="12" m="10" l="6" xl="4" xxl="3">
          <GroupuiInput
            placeholder={t('page.contact.phoneNumber-placeholder')}
            data-testid="input-alternativePhoneNumber"
            maxlength={200}
            readonly={!props.isEditable}
            required={false}
            value={alternativePhoneNumber}
            pattern={phoneNumberPattern}
            severity={isAlternativePhoneNumberValid ? 'none' : 'danger'}
            onInput={(ev: FormEvent) => handleAlternativePhoneNumber((ev.target as HTMLInputElement).value)}
          >
            <span slot="label">{t('page.contact.alternativePhoneNumber-label')}</span>
            <span slot="description">{t('page.contact.phoneNumber-validation-error')}</span>
          </GroupuiInput>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="10" l="6" xl="4" xxl="3">
          <p className="own-field-label">{t('page.contact.chooseLanguageLabel')}</p>
          <GroupuiMultiselect
            data-testid="multiselect-language"
            key={t('page.contact.chooseLanguageLabel').toString() + languageInit} // languageInit is needed as workaround to show selected languages
            onGroupuiChange={(ev: CustomEvent) => handleLanguage(ev)}
            maxHeight={'400px'}
            disabled={!props.isEditable}
          >
            {getAllNames().map(name => (
              <GroupuiMultiselectOption
                data-testid={`multiselectoption-language-${getCode(name)}`}
                key={name}
                value={getCode(name)}
                checked={selectedLanguages.includes(getCode(name))}
              >
                {t(`languagecodes:${getCode(name)}.name`)}
              </GroupuiMultiselectOption>
            ))}
          </GroupuiMultiselect>
          <p className="own-field-description">{t('page.contact.chooseLanguageDescription')}</p>
        </GroupuiGridCol>
      </GroupuiGridRow>

      {comment && comment.length > 0 && (
        <GroupuiGridRow type="fixed">
          <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
            <GroupuiTextarea
              className={'align-comment-section'}
              placeholder={t('page.contact.comment-placeholder')}
              data-testid="input-comment"
              rows={2}
              maxlength={500}
              disabled={!props.isEditable}
              required={false}
              value={comment}
              resize={'vertical'}
              severity={isCommentValid ? 'none' : 'danger'}
              onInput={(ev: FormEvent) => handleComment((ev.target as HTMLInputElement).value)}
            >
              <span slot="label">{t('page.contact.commentLabel')}</span>
              {(!isCommentValid || comment) && (
                <span slot="description">{t('page.contact.comment-validation-error')}</span>
              )}
            </GroupuiTextarea>
          </GroupuiGridCol>
        </GroupuiGridRow>
      )}
    </Fragment>
  );
};

export default BasicData;
