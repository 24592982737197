import { GroupuiCard, GroupuiDivider, GroupuiIcon, GroupuiText } from '@sdc-wob-type-3/group-ui-react';
import { Ref } from 'react';
import './HelpMenu.css';
import { useNavigate } from 'react-router-dom';

type HelpMenuProps = {
  helpmenuref: Ref<HTMLGroupuiCardElement>;
  handleClick: any;
};

const HelpMenu = (props: HelpMenuProps) => {
  const navigate = useNavigate();
  const mailTo = () => {
    window.open(`mailto:B2Bteam@vwgroupsupply.com?subject=CIS-Support:`, '_self');
    props.handleClick();
  };
  const navigateToFAQ = () => {
    props.handleClick();
    navigate('/faq');
  };

  return (
    <GroupuiCard id="header-help-menu" ref={props.helpmenuref}>
      <div className="header-menu-item-container">
        <GroupuiIcon
          id="header-help-icon"
          data-testid="header-help-icon"
          name="help-24"
          className="header-help-menu-icon"
          onClick={() => navigateToFAQ()}
        />
        <GroupuiText className="header-help-menu-item" onClick={() => navigateToFAQ()}>
          FAQ
        </GroupuiText>
      </div>

      <GroupuiDivider id="header-help-menu-divider" />

      <div className="header-menu-item-container">
        <GroupuiIcon
          id="header-support-icon"
          data-testid="header-support-icon"
          name="email-24"
          className="header-help-menu-icon"
          onClick={() => mailTo()}
        />
        <GroupuiText className="header-help-menu-item" onClick={() => mailTo()}>
          Support
        </GroupuiText>
      </div>
    </GroupuiCard>
  );
};

HelpMenu.defaultProps = {
  helpmenuref: null,
};

export default HelpMenu;
