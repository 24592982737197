import { GroupuiHeadline, GroupuiText } from '@sdc-wob-type-3/group-ui-react';
import { useTranslation } from 'react-i18next';
import CentralIcon from '../../assets/CentralIcon';
import EasyIcon from '../../assets/EasyIcon';
import PrivacyIcon from '../../assets/PrivacyIcon';
import './Advantages.css';

const Advantages = () => {
  const { t } = useTranslation();

  return (
    <div id="advantages-container">
      <div className="margin-bottom-50">
        <EasyIcon />
        <GroupuiHeadline heading="h3" weight="thin">
          {t('page.landingpage.easyTitle')}
        </GroupuiHeadline>
        <GroupuiText className="advantages-description">{t('page.landingpage.easyDescription')}</GroupuiText>
      </div>

      <div className="margin-left-75 margin-bottom-50">
        <PrivacyIcon />
        <GroupuiHeadline heading="h3" weight="thin">
          {t('page.landingpage.privacyTitle')}
        </GroupuiHeadline>
        <GroupuiText className="advantages-description">{t('page.landingpage.privacyDescription')}</GroupuiText>
      </div>

      <div className="margin-left-75 margin-bottom-50">
        <CentralIcon />
        <GroupuiHeadline heading="h3" weight="thin">
          {t('page.landingpage.centralTitle')}
        </GroupuiHeadline>
        <GroupuiText className="advantages-description">{t('page.landingpage.centralDescription')}</GroupuiText>
      </div>
    </div>
  );
};

export default Advantages;
