import { GroupuiText } from '@sdc-wob-type-3/group-ui-react';
import { useTranslation } from 'react-i18next';
import { IMPRINT } from '../imprint/Imprint';
import { PRIVACY_POLICY } from '../privacy-policy/PrivacyPolicy';
import './Footer.css';
import { Link } from 'react-router-dom';
import VWGroupLogo from '../../assets/VWGroupLogo';
import { TERMS_OF_USE } from '../terms-of-use/TermsOfUse';
import { lang } from '../header/languagemenu/LanguageUseCase';
import { LICENSE } from '../../App';

const Footer = () => {
  const { t } = useTranslation();
  let whistleBlowerLanguage = lang.getLanguage() === 'de' ? 'de' : 'en';
  let WHISTLEBLOWER_SYSTEM = `https://www.volkswagenag.com/${whistleBlowerLanguage}/group/compliance-and-risk-management/whistleblowersystem.html`;

  return (
    <div id="footer-container">
      <div id="footer">
        <div className="footer-seperator" />
        <div id="footer-nav">
          <Link to={`${PRIVACY_POLICY}`} className="no-decoration ">
            <GroupuiText className="no-linebreak margin-5 font-size-14">{t('page.landingpage.privacy')}</GroupuiText>
          </Link>

          <span className="divider"> | </span>

          <Link to={`${IMPRINT}`} className="no-decoration">
            <GroupuiText className="no-linebreak margin-5 font-size-14">{t('page.landingpage.imprint')}</GroupuiText>
          </Link>

          <span className="divider"> | </span>

          <Link to={`${TERMS_OF_USE}`} className="no-decoration">
            <GroupuiText className="no-linebreak margin-5 font-size-14">{t('page.landingpage.termsOfUse')}</GroupuiText>
          </Link>

          <span className="divider"> | </span>

          <a href={WHISTLEBLOWER_SYSTEM} target="_blank" className="no-decoration">
            <GroupuiText className="no-linebreak margin-5 font-size-14">
              {t('page.landingpage.whistleblower')}
            </GroupuiText>
          </a>

          <span className="divider"> | </span>

          <Link to={`${LICENSE}`} className="no-decoration">
            <GroupuiText className="no-linebreak margin-5 font-size-14">{t('page.landingpage.license')}</GroupuiText>
          </Link>
        </div>
        <div className="footer-seperator" />
      </div>
      <VWGroupLogo />
    </div>
  );
};

export default Footer;
