import { GroupuiCard, GroupuiDivider, GroupuiText } from '@sdc-wob-type-3/group-ui-react';
import { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageMenu.css';
import { lang } from './LanguageUseCase';

type LanguageMenuProps = {
  languagemenuref: Ref<HTMLGroupuiCardElement>;
  handleClick: any;
};

const LanguageMenu = (props: LanguageMenuProps) => {
  const { i18n } = useTranslation();

  const handleLanguageSelection = (language: string) => {
    lang.setLocalLanguage(i18n, language);
    props.handleClick();
  };

  return (
    <GroupuiCard id="header-language-menu" ref={props.languagemenuref}>
      <GroupuiText className="header-language-menu-item" onClick={() => handleLanguageSelection('de')}>
        {'Deutsch'}
      </GroupuiText>

      <GroupuiDivider id="header-language-menu-divider" className="header-language-menu-divider" />

      <GroupuiText className="header-language-menu-item" onClick={() => handleLanguageSelection('en')}>
        {'English'}
      </GroupuiText>

      <GroupuiDivider id="header-language-menu-divider-2" className="header-language-menu-divider" />

      <GroupuiText className="header-language-menu-item" onClick={() => handleLanguageSelection('pl')}>
        {'po polsku'}
      </GroupuiText>
    </GroupuiCard>
  );
};

LanguageMenu.defaultProps = {
  languagemenuref: null,
};

export default LanguageMenu;
