import {
  GroupuiButton,
  GroupuiGridCol,
  GroupuiGridRow,
  GroupuiHeadline,
  GroupuiInput,
  GroupuiText,
} from '@sdc-wob-type-3/group-ui-react';
import React, { FormEvent, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import CisLogo from '../../assets/CisLogo';
import LogisticsWorldMap from '../../assets/LogisticsWorldMap';
import Advantages from '../advantages/Advantages';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { CisNotification } from '../notification-center/CisNotification';
import './GetLoginPage.css';
import { ILoginLinkService, LoginLinkService } from './LoginLinkService';
import NotificationCenter from '../notification-center/NotificationCenter';

export const GET_LOGIN = '/get-login';

type GetLoginPageProps = {
  loginLinkService: ILoginLinkService;
};

const GetLoginPage = (props: GetLoginPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setEmailValid] = useState<boolean>(true);
  const emailPattern = '^(?=^[a-zA-Z0-9\\-_.@]{8,64}$)[a-zA-Z0-9\\-_.]+@[a-zA-Z0-9\\-.]+[.]{1}[a-zA-Z]{2,}$';

  const handleEmail = (input: string) => {
    setEmail(input);
    setEmailValid(new RegExp(emailPattern).test(input));
  };

  const handleRequest = () => {
    props.loginLinkService
      .request(email)
      .then(() =>
        navigate(location.pathname, {
          replace: true,
          state: [new CisNotification(t('notification.success'), 'success')],
        })
      )
      .catch(err =>
        navigate(location.pathname, { replace: true, state: [new CisNotification(t('notification.error'), 'danger')] })
      );
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (isEmailValid && e.key === 'Enter') {
      handleRequest();
    }
  };

  return (
    <div id="landing-page">
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header />
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="5" l="6" xl="7" xxl="8" />
        <GroupuiGridCol s="12" m="7" l="6" xl="5" xxl="4">
          <NotificationCenter notifications={useLocation().state as CisNotification[]} />
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="6" xxl="6" className="hidden-l">
          <LogisticsWorldMap id="logistics-worldmap" />
        </GroupuiGridCol>

        <GroupuiGridCol s="12" m="12" l="12" xl="6" xxl="6">
          <div id="logo-headline-mail-container">
            <div id="cis-logo-headline-container" className="flex-end">
              <div id="cis-logo-headline">
                <CisLogo id="cis-logo-body" />

                <GroupuiHeadline heading="h1" weight="thin">
                  {t('page.landingpage.title')}
                </GroupuiHeadline>
              </div>
            </div>

            <div id="email-container">
              <GroupuiText id="email-description" size="caption">
                {t('page.getloginpage.emailDescription')}
              </GroupuiText>
              <GroupuiInput
                placeholder="E-Mail"
                data-testid="input-email"
                maxlength={200}
                required={true}
                value={email}
                pattern={emailPattern}
                severity={isEmailValid ? 'none' : 'danger'}
                onInput={(ev: FormEvent) => handleEmail((ev.target as HTMLInputElement).value)}
                onKeyPress={(ev: KeyboardEvent) => handleEnter(ev)}
              />

              <GroupuiButton
                id="request-button"
                data-testid="request-button"
                variant="primary"
                inverted={true}
                disabled={!isEmailValid || email.length === 0}
                onClick={handleRequest}
              >
                {t('page.getloginpage.requestButton')}
              </GroupuiButton>
            </div>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <div id="landingpage-advantages-container">
            <Advantages />
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <div className="flex-end">
            <GroupuiText id="only-europe-getlogin" size="caption">
              {t('page.landingpage.onlyEurope')}
            </GroupuiText>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <Footer />
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
      </GroupuiGridRow>
    </div>
  );
};

GetLoginPage.defaultProps = {
  loginLinkService: new LoginLinkService(),
} as GetLoginPageProps;

export default GetLoginPage;
