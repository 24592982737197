import * as React from 'react';

function PrivacyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4675 0C55.0557 0 70.935 15.8794 70.935 35.4676C70.935 44.7891 67.339 53.2707 61.4586 59.6009L85 83.1423L83.1423 85L59.6009 61.4586C53.2707 67.3391 44.7891 70.9351 35.4675 70.9351C15.8793 70.9351 0 55.0557 0 35.4676C0 15.8794 15.8793 0 35.4675 0ZM35.4675 2.62739C17.3303 2.62739 2.6272 17.3305 2.6272 35.4677C2.6272 53.6049 17.3303 68.308 35.4675 68.308C53.6047 68.308 68.3078 53.6049 68.3078 35.4677C68.3078 17.3305 53.6047 2.62739 35.4675 2.62739Z"
        fill="#20607E"
      />
      <g filter="url(#filter0_d_1526_69281)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5801 20.285V37.2162C19.5801 43.0134 22.7746 47.7848 26.9387 51.5458C30.4543 54.7211 34.3221 56.8546 36.4605 57.9013C38.5989 56.8546 42.4667 54.7211 45.9823 51.5458C50.1465 47.7848 53.3409 43.0134 53.3409 37.2162V20.285C52.7301 19.8833 51.89 19.3923 50.8057 18.8904C47.9332 17.561 43.2621 16.1156 36.4605 16.1156C29.659 16.1156 24.9879 17.561 22.1154 18.8904C21.031 19.3923 20.191 19.8833 19.5801 20.285ZM15.36 18.2257V37.2162C15.36 52.1681 31.9146 60.4981 35.6972 62.2063C36.191 62.4293 36.73 62.4293 37.2238 62.2063C41.0065 60.4981 57.5611 52.1681 57.5611 37.2162V18.2257C57.5611 18.2257 51.3069 11.8955 36.4605 11.8955C21.6141 11.8955 15.36 18.2257 15.36 18.2257Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.5028 28.1583L33.7324 42.9287C32.9084 43.7527 31.5724 43.7527 30.7484 42.9287L24.4182 36.5985L27.4023 33.6144L32.2404 38.4526L45.5188 25.1742L48.5028 28.1583Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1526_69281"
          x="-1.30035"
          y="3.45508"
          width="75.5217"
          height="75.522"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1526_69281" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1526_69281" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default PrivacyIcon;
