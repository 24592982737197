import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GetLoginPage, { GET_LOGIN } from './components/getlogin-page/GetLoginPage';
import Imprint, { IMPRINT } from './components/imprint/Imprint';
import LandingPage from './components/landing-page/LandingPage';
import PrivacyPolicy, { PRIVACY_POLICY } from './components/privacy-policy/PrivacyPolicy';
import ContactPage, { CONTACT_PATH } from './components/contact/ContactPage';
import TermsOfUse, { TERMS_OF_USE } from './components/terms-of-use/TermsOfUse';
import License from './components/license/License';
import FAQ from './components/faq/FAQ';
import ErrorPage from './components/error/ErrorPage';
import { LoggedoutPage, LOGGED_OUT_PATH } from './components/loggedout/LoggedoutPage';
import { IdleLogoutPage, IDLE_LOGOUT_PATH } from './components/loggedout/IdleLogoutPage';

export const LICENSE = '/license';
export const FAQ_PAGE = '/faq';

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path={GET_LOGIN} element={<GetLoginPage />} />
        <Route path={CONTACT_PATH} element={<ContactPage />} />
        <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={IMPRINT} element={<Imprint />} />
        <Route path={TERMS_OF_USE} element={<TermsOfUse />} />
        <Route path={LICENSE} element={<License />} />
        <Route path={FAQ_PAGE} element={<FAQ />} />
        <Route path={LOGGED_OUT_PATH} element={<LoggedoutPage />} />
        <Route path={IDLE_LOGOUT_PATH} element={<IdleLogoutPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}
