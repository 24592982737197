import { Fragment, useRef, useState } from 'react';
import { GroupuiButton, GroupuiHeadline, GroupuiIcon } from '@sdc-wob-type-3/group-ui-react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import LanguageMenu from './languagemenu/LanguageMenu';
import useOnClickOutside from './languagemenu/UseOnClickOutside';
import CisLogoHeader from '../../assets/CisLogoHeader';
import environment from '../../environment';
import HelpMenu from './helpmenu/HelpMenu';

type HeaderProps = {
  showLoginButton: boolean;
};

const Header = (props: HeaderProps) => {
  const [showLanguageMenu, setLanguageShowMenu] = useState(false);
  const [showHelpMenu, setHelpShowMenu] = useState(false);
  const wrapperRef = useRef(null);

  const handleLanguageMenu = () => {
    setLanguageShowMenu(!showLanguageMenu);
    setHelpShowMenu(false);
  };

  const handleHelpMenu = () => {
    setHelpShowMenu(!showHelpMenu);
    setLanguageShowMenu(false);
  };

  const handleClickOutside = (event: any) => {
    //on click outside close both menues
    const LANGUAGE_ICON = 'header-language-icon';
    const HELP_ICON = 'header-help-icon';

    const clickedOnLanguageIcon =
      event.composedPath()[0].id === LANGUAGE_ICON || event.composedPath()[1].id === LANGUAGE_ICON;
    if (!clickedOnLanguageIcon) {
      setLanguageShowMenu(false);
    }

    const clickedOnHelpIcon = event.composedPath()[0].id === HELP_ICON || event.composedPath()[1].id === HELP_ICON;
    if (!clickedOnHelpIcon) {
      setHelpShowMenu(false);
    }
  };

  const handleLogin = () => {
    window.location.href = `${environment.PRIVATE_UI_URL}`;
  };

  useOnClickOutside(wrapperRef, handleClickOutside);

  return (
    <Fragment>
      <div id="header-container">
        <NavLink className="no-textdecoration" to="/">
          <div id="cis-logo-container">
            <CisLogoHeader id="cis-logo-header" />
            <GroupuiHeadline id="logo-text" heading="h4" weight="regular" serif={false}>
              cis
            </GroupuiHeadline>
          </div>
        </NavLink>

        <div id="nav-container">
          <GroupuiIcon
            id="header-help-icon"
            data-testid="header-help-icon"
            name="help-24"
            className="header-icon"
            onClick={handleHelpMenu}
          />
          <GroupuiIcon
            id="header-language-icon"
            data-testid="language-icon"
            name="global-24"
            className="header-icon"
            onClick={handleLanguageMenu}
          />

          {props.showLoginButton && (
            <GroupuiButton
              id="login-button"
              variant="primary"
              inverted={true}
              onClick={handleLogin}
              data-testid="login-button"
            >
              VW Group Login
            </GroupuiButton>
          )}
        </div>
      </div>
      {showHelpMenu && (
        <div id="helpmenu-container">
          <HelpMenu helpmenuref={wrapperRef} handleClick={handleHelpMenu} />
        </div>
      )}

      {showLanguageMenu && (
        <div id="languagemenu-container">
          <LanguageMenu languagemenuref={wrapperRef} handleClick={handleLanguageMenu} />
        </div>
      )}
    </Fragment>
  );
};

Header.defaultProps = {
  showLoginButton: false,
};

export default Header;
