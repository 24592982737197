export const LANGUAGES = {
  cs: {
    name: 'Czech',
    nativeName: 'čeština',
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch',
  },
  en: {
    name: 'English',
    nativeName: 'English',
  },
  es: {
    name: 'Spanish',
    nativeName: 'Español',
  },
  fr: {
    name: 'French',
    nativeName: 'Français',
  },
  hu: {
    name: 'Hungarian',
    nativeName: 'magyar',
  },
  it: {
    name: 'Italian',
    nativeName: 'Italiano',
  },
  pl: {
    name: 'Polish',
    nativeName: 'po polsku',
  },
  pt: {
    name: 'Portuguese',
    nativeName: 'Português',
  },
  ro: {
    name: 'Romanian',
    nativeName: 'Română',
  },
  ru: {
    name: 'Russian',
    nativeName: 'Русский',
  },
  sk: {
    name: 'Slovak',
    nativeName: 'slovenčina',
  },
  tr: {
    name: 'Turkish',
    nativeName: 'Türkçe',
  },
};
