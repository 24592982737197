import i18n from 'i18next';

export namespace lang {
  const LANGUAGE_TOKEN = 'i18nextLng';

  export function setLocalLanguage(i18next: typeof i18n, language: string) {
    if (!language) {
      return;
    }
    i18next.changeLanguage(language);
    setToken(language);
  }

  export function setToken(language: string) {
    localStorage.setItem(LANGUAGE_TOKEN, language);
  }

  export function getLanguage(): string {
    let item = localStorage.getItem(LANGUAGE_TOKEN);
    if (!item) {
      return 'en';
    }
    return item.substring(0, 2);
  }

  export function removeToken() {
    localStorage.removeItem(LANGUAGE_TOKEN);
  }
}
