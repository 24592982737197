import React from 'react';
import { GroupuiGridRow, GroupuiGridCol, GroupuiHeadline } from '@sdc-wob-type-3/group-ui-react';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import BookIcon from '../../assets/BookIcon';
import './ErrorPage.css';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div id="error-container">
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header showLoginButton={true} />
        </GroupuiGridCol>
      </GroupuiGridRow>
      <BookIcon id="error-image" />
      <GroupuiHeadline id="error-headline" heading="h2" weight="bold" serif={false}>
        {t('components.errorPage.notFoundMessage')}
      </GroupuiHeadline>

      <Footer />
    </div>
  );
};

export default ErrorPage;
