import React from 'react';
import './NotificationCenter.css';
import { GroupuiNotification } from '@sdc-wob-type-3/group-ui-react';
import { CisNotification } from './CisNotification';

type NotificationCenterProps = {
  notifications: CisNotification[];
};

const NotificationCenter = (props: NotificationCenterProps) => {
  return (
    <div id="notification-center">
      {props.notifications &&
        props.notifications
          .filter(notification => {
            return new Date().getTime() - notification.created.getTime() < 5000;
          })
          .map((notification, index) => {
            return (
              <GroupuiNotification key={notification.created.getTime() + index} severity={notification.severity}>
                {notification.message}
              </GroupuiNotification>
            );
          })}
    </div>
  );
};

NotificationCenter.defaultProps = {
  notifications: [],
};

export default NotificationCenter;
