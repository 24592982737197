import * as React from 'react';

function CisLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={242} height={176} viewBox="0 0 242 176" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M102.36 23.752c0-10.084 8.17-18.252 18.252-18.252h0c10.084 0 18.252 8.168 18.252 18.252h0c0 10.084-8.17 18.248-18.252 18.248h0c-10.081 0-18.251-8.164-18.251-18.248"
        fill="none"
        stroke="#A80044"
        strokeWidth={11}
      />
      <path
        d="M28.16 43.8c0-6.902 5.602-12.5 12.503-12.5h0c6.9 0 12.497 5.598 12.497 12.5h0c0 6.904-5.594 12.502-12.497 12.502h0c-6.901 0-12.503-5.6-12.503-12.501M187.56 43.866c0-6.903 5.602-12.501 12.503-12.501h0c6.9 0 12.498 5.598 12.498 12.501h0c0 6.903-5.595 12.501-12.498 12.501h0c-6.9 0-12.502-5.6-12.502-12.501"
        fill="none"
        stroke="#A80044"
        strokeWidth={11.6}
      />
      <path
        d="M110.468 56.039c-10.966 0-22.154 4.505-26.907 15.028-1.826 3.98-20.855 45.493-30.177 66.072-2.535 5.65-6.13 9.452-12.726 9.602-7.674-.186-12.767-6.31-13.645-13.608-.798-5.51-3.038-20.783-3.502-23.95-.27-4.802-4.087-5.757-7.99-5.757 2.5-5.704 4.973-11.445 7.858-16.966 2.027-3.878 5.671-4.966 9.818-4.966h15.076c3.122 0 6.238.696 8.391 3.113a6.102 6.102 0 009.115-8.111c-4.455-4.997-10.922-7.218-17.505-7.218H33.197c-8.757 0-16.439 3.592-20.604 11.548-4.457 8.53-7.936 17.439-12.006 26.132-1.952 4.177 1.226 8.685 5.62 8.685h5.945l2.784 19.168c1.893 13.249 11.652 23.979 25.532 24.078 11.631.084 19.482-6.74 24.1-16.88 9.276-20.475 28.269-62.003 29.819-65.395 2.933-6.366 9.453-8.36 15.976-8.36h20.435c6.522 0 13.041 1.994 15.976 8.36 1.551 3.392 20.544 44.92 29.819 65.395 4.618 10.14 12.47 16.964 24.1 16.88 13.88-.1 23.638-10.83 25.532-24.078l2.784-19.168h5.944c4.394 0 7.572-4.508 5.621-8.685-4.071-8.693-7.55-17.602-12.007-26.132-4.164-7.956-11.846-11.548-20.603-11.548h-15.076c-6.584 0-13.05 2.22-17.506 7.218a6.1 6.1 0 109.114 8.111c2.154-2.417 5.269-3.113 8.392-3.113h15.076c4.146 0 7.792 1.088 9.817 4.966 2.886 5.52 5.359 11.262 7.859 16.966-3.904 0-7.721.955-7.99 5.758-.465 3.166-2.704 18.439-3.502 23.949-.879 7.299-5.971 13.422-13.646 13.608-6.594-.15-10.191-3.951-12.725-9.602-9.322-20.58-28.352-62.092-30.176-66.072-4.753-10.523-15.943-15.028-26.909-15.028h-20.224z"
        fill="#A80044"
      />
      <path
        d="M144.916 121.63l-3.053 20.954c-1.588 10.943-9.46 20.793-21.282 20.673-11.823.12-19.694-9.73-21.283-20.673l-3.053-20.953a6.097 6.097 0 00-6.894-5.178 6.1 6.1 0 00-5.178 6.895l3.053 20.953c2.486 17.146 15.326 31.127 33.355 31.127 18.029 0 30.868-13.981 33.355-31.127l3.052-20.953a6.097 6.097 0 00-5.177-6.895 6.1 6.1 0 00-6.895 5.178"
        fill="#A80044"
      />
    </svg>
  );
}

export default CisLogo;
