import React, { Fragment, useEffect, useState } from 'react';
import { Contact } from './Contact';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  GroupuiButton,
  GroupuiGridCol,
  GroupuiGridRow,
  GroupuiHeadline,
  GroupuiText,
} from '@sdc-wob-type-3/group-ui-react';
import { useTranslation } from 'react-i18next';
import './ContactPage.css';
import BasicData from './basicdata/BasicData';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { CisNotification } from '../notification-center/CisNotification';
import CisLogo from '../../assets/CisLogo';
import { TERMS_OF_USE } from '../terms-of-use/TermsOfUse';
import { ContactService } from './ContactService';
import { PRIVACY_POLICY } from '../privacy-policy/PrivacyPolicy';
import NotificationCenter from '../notification-center/NotificationCenter';
import ConfirmModal from '../common/ConfirmModal';
import { getPathParams } from '../../utils/UrlUtils';

export const CONTACT_PATH = '/contacts/:token';

type ContactProps = {
  contactService: ContactService;
};

const ContactPage = (props: ContactProps) => {
  const { t } = useTranslation();
  const [contact, setContact] = useState<Contact>();
  const { token }: any = getPathParams(useParams());
  const navigate = useNavigate();
  const GET_LOGIN_LINK_PATH = '/get-login';
  const [loaded, isLoaded] = useState<boolean>(false);
  const [showButtons, setShowButtons] = useState<boolean>(true);
  const [isDirty, setDirty] = useState<boolean>(false);
  const [isEditable, setEditable] = useState<boolean>(true);
  const [isValid, setValid] = useState<boolean>(true);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setEditable(true);
    props.contactService
      .findByLoginToken(token)
      .then(result => {
        setContact(result);
        isLoaded(true);
        return true;
      })
      .catch(() => {
        isLoaded(true);
      });
  }, []);

  const handleRequest = () => {
    navigate(GET_LOGIN_LINK_PATH);
  };

  const sendConsent = () => {
    setEditable(false);
    if (contact) {
      props.contactService
        .consent(token, contact)
        .then(() => {
          navigate(location.pathname, {
            replace: true,
            state: [new CisNotification(t('notification.consent-success'), 'success')],
          });
          setShowButtons(false);
          return true;
        })
        .catch(() => {
          navigate(location.pathname, {
            replace: true,
            state: [new CisNotification(t('notification.error'), 'danger')],
          });
        });
    } else {
      navigate(location.pathname, { replace: true, state: [new CisNotification(t('notification.error'), 'danger')] });
    }
  };

  const onRejectData = () => {
    setEditable(false);
    if (contact) {
      props.contactService
        .reject(token, contact)
        .then(() => {
          navigate(location.pathname, {
            replace: true,
            state: [new CisNotification(t('notification.reject-success'), 'success')],
          });
          setShowButtons(false);
          return true;
        })
        .catch(() => {
          navigate(location.pathname, {
            replace: true,
            state: [new CisNotification(t('notification.error'), 'danger')],
          });
        });
    }
    setConfirmModalVisible(false);
  };
  const cancelRejection = () => {
    setConfirmModalVisible(false);
  };

  return (
    <div id="page">
      <ConfirmModal
        isVisible={isConfirmModalVisible}
        confirmQuestion={t('page.contact.rejection-question')}
        confirmText={t('page.contact.rejection-text')}
        confirm={onRejectData}
        cancel={cancelRejection}
        setInvisible={() => setConfirmModalVisible(false)}
        confirmBtnText={t('page.contact.rejection-btn-text')}
        cancelBtnText={t('page.common.cancel')}
      />

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header />
        </GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="5" l="6" xl="7" xxl="8" />
        <GroupuiGridCol s="12" m="7" l="6" xl="5" xxl="4">
          <NotificationCenter notifications={useLocation().state as CisNotification[]} />
        </GroupuiGridCol>
      </GroupuiGridRow>

      <div id="basic-data-container">
        <GroupuiGridRow type="fixed">
          <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
            <Fragment>
              <CisLogo id="contact-cis-logo" />
              <GroupuiHeadline id="contact-header" heading="h3">
                Contact Information Service
              </GroupuiHeadline>

              {!loaded && <GroupuiText>{t('page.contact.loading')}</GroupuiText>}
              {!contact && loaded && (
                <div id="invalid-token-area">
                  <GroupuiText>{t('page.contact.invalidToken')}</GroupuiText>

                  <GroupuiButton
                    id="to-getloginlink-button"
                    data-testid="page.contact.toGetloginlinkButton"
                    variant="primary"
                    inverted={true}
                    onClick={handleRequest}
                  >
                    {t('page.contact.toGetloginlinkButton')}
                  </GroupuiButton>
                </div>
              )}
              {contact && !contact.firstName && <GroupuiText>{t('page.contact.noUserData')}</GroupuiText>}
            </Fragment>
          </GroupuiGridCol>
        </GroupuiGridRow>

        {contact && contact.firstName && (
          <Fragment>
            <GroupuiGridRow type="fixed">
              <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
                <GroupuiHeadline id="contact-subheader" heading="h4">
                  {t('page.contact.personalData')}
                </GroupuiHeadline>

                <GroupuiText className="margin-bottom-25">{t('page.contact.info-text-1')}</GroupuiText>
              </GroupuiGridCol>
            </GroupuiGridRow>

            <BasicData validContact={contact} isDirty={setDirty} isEditable={isEditable} isValid={setValid} />

            <GroupuiGridRow type="fixed">
              <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
                <br />

                <GroupuiText>{t('page.contact.info-text-3')}</GroupuiText>

                <GroupuiText>{t('page.contact.info-text-4')}</GroupuiText>

                <br />

                <GroupuiText weight="bold">{t('page.contact.info-text-5')}</GroupuiText>

                <br />

                <GroupuiText>{t('page.contact.info-text-6')}</GroupuiText>

                <br />

                <GroupuiText>{t('page.contact.info-text-7')}</GroupuiText>

                <GroupuiText>
                  {t('page.contact.info-text-8')}{' '}
                  <a className="contact-link" href={TERMS_OF_USE}>
                    {t('page.contact.info-text-9')}
                  </a>{' '}
                  {t('page.contact.info-text-10')}
                </GroupuiText>

                <br />

                <GroupuiText>
                  {t('page.contact.info-text-11')}{' '}
                  <a className="contact-link" href={PRIVACY_POLICY}>
                    {t('page.contact.info-text-12')}
                  </a>{' '}
                </GroupuiText>

                {showButtons && !isDirty && (
                  <GroupuiButton
                    id="send-consent-button"
                    data-testid="send-consent-button"
                    variant="primary"
                    inverted={true}
                    disabled={!isValid}
                    onClick={sendConsent}
                  >
                    {t('page.contact.consent')}
                  </GroupuiButton>
                )}

                {showButtons && isDirty && (
                  <GroupuiButton
                    id="send-consent-button"
                    data-testid="send-consent-button"
                    variant="primary"
                    inverted={true}
                    disabled={!isValid}
                    onClick={sendConsent}
                  >
                    {t('page.contact.save-and-consent')}
                  </GroupuiButton>
                )}

                {showButtons && (
                  <GroupuiButton
                    id="send-reject-button"
                    data-testid="send-reject-button"
                    variant="primary"
                    inverted={true}
                    disabled={!isValid}
                    onClick={() => setConfirmModalVisible(true)}
                  >
                    {t('page.contact.reject')}
                  </GroupuiButton>
                )}
              </GroupuiGridCol>
            </GroupuiGridRow>
          </Fragment>
        )}
      </div>
      <Footer />
    </div>
  );
};

ContactPage.defaultProps = {
  contactService: new ContactService(),
};

export default ContactPage;
