import {
  GroupuiButton,
  GroupuiGridCol,
  GroupuiGridRow,
  GroupuiHeadline,
  GroupuiIcon,
  GroupuiText,
} from '@sdc-wob-type-3/group-ui-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FAQ_PAGE } from '../../App';
import CisLogo from '../../assets/CisLogo';
import ContactDataIllustration from '../../assets/ContactDataIllustration';
import DevicesIllustration from '../../assets/DevicesIllustration';
import FavoritesIllustration from '../../assets/FavoritesIllustration';
import LogisticsWorldMap from '../../assets/LogisticsWorldMap';
import MyDataIllustration from '../../assets/MyDataIllustration';
import Advantages from '../advantages/Advantages';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './LandingPage.css';

export const GET_LOGIN_LINK_PATH = '/get-login';

const LandingPage = () => {
  const { t } = useTranslation();
  const featuresRef = useRef<HTMLInputElement>(null);

  const executeScroll = () => featuresRef.current?.scrollIntoView();
  const navigate = useNavigate();

  const handleMyData = () => {
    navigate(GET_LOGIN_LINK_PATH);
  };
  const handleMailtoSupport = () => {
    window.open(`mailto:B2Bteam@vwgroupsupply.com?subject=CIS-Support:`, '_self');
  };

  return (
    <div id="landing-page">
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header showLoginButton={true} />
        </GroupuiGridCol>
      </GroupuiGridRow>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="0" l="0" xl="6" xxl="6" className="hidden-l">
          <LogisticsWorldMap id="logistics-worldmap" />
        </GroupuiGridCol>

        <GroupuiGridCol s="12" m="12" l="12" xl="6" xxl="6">
          <div id="cis-logo-headline-container" className="flex-end">
            <div id="cis-logo-headline">
              <CisLogo id="cis-logo-body" />

              <GroupuiHeadline heading="h1" weight="thin">
                {t('page.landingpage.title')}
              </GroupuiHeadline>
            </div>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <div id="landingpage-advantages-container">
            <Advantages />
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>
      <div id="learn-more-button-container">
        <GroupuiButton id="learn-more-button" variant="primary" inverted={true} onClick={executeScroll}>
          {t('page.landingpage.learnMore')}
        </GroupuiButton>
      </div>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <div className="flex-end">
            <GroupuiText id="only-europe" size="caption">
              {t('page.landingpage.onlyEurope')}
            </GroupuiText>
          </div>
        </GroupuiGridCol>
      </GroupuiGridRow>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="1" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="10" m="10" l="10" xl="10" xxl="10">
          <div className="seperator" />
        </GroupuiGridCol>
        <GroupuiGridCol s="1" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>
      <div ref={featuresRef}>
        <GroupuiText id="features-headline" className="headline-small" size="small" weight="bold">
          {t('page.landingpage.features')}
        </GroupuiText>
      </div>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <div className="features-left">
            <MyDataIllustration className="feature-illustration" />
            <div className="features-headline-description-container">
              <GroupuiHeadline className="features-headline" heading="h3" weight="thin">
                {t('page.landingpage.controlTitle')}
              </GroupuiHeadline>
              <GroupuiText className="features-description" size="small">
                {t('page.landingpage.controlDescription')}
              </GroupuiText>

              <GroupuiButton
                id="mydata-button"
                variant="primary"
                inverted={true}
                onClick={handleMyData}
                data-testid="mydata-button"
              >
                {t('page.landingpage.showData')}
              </GroupuiButton>
            </div>
          </div>
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <div className="features-right">
            <ContactDataIllustration className="feature-illustration" />
            <div className="features-headline-description-container">
              <GroupuiHeadline className="features-headline" heading="h3" weight="thin">
                {t('page.landingpage.contactdataTitle')}
              </GroupuiHeadline>
              <GroupuiText className="features-description" size="small">
                {t('page.landingpage.contactdataDescription')}
              </GroupuiText>
            </div>
          </div>
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <div className="features-left">
            <FavoritesIllustration className="feature-illustration" />
            <div className="features-headline-description-container">
              <GroupuiHeadline heading="h3" weight="thin">
                {t('page.landingpage.favoritesTitle')}
              </GroupuiHeadline>
              <GroupuiText className="features-description" size="small">
                {t('page.landingpage.favoritesDescription')}
              </GroupuiText>
            </div>
          </div>
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <div id="devices-container" className="features-center">
            <div id="devices-headline-description">
              <GroupuiHeadline heading="h3" weight="thin">
                {t('page.landingpage.devicesTitle')}
              </GroupuiHeadline>
              <GroupuiText size="small">{t('page.landingpage.devicesDescription')}</GroupuiText>
            </div>
            <DevicesIllustration className="feature-illustration" />
          </div>
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <div className="footer-seperator" />
          <div id="devices-container" className="features-center">
            <div id="devices-headline-description">
              <GroupuiHeadline heading="h3" weight="thin">
                {t('page.landingpage.faq-title')}
              </GroupuiHeadline>
              <div className="faq-link-container">
                <GroupuiText size="small">{t('page.landingpage.faq-hint-1')} </GroupuiText>
                &nbsp;
                <Link to={`${FAQ_PAGE}`} className="landing-page-faq-link" data-testid="landing-page-faq-link">
                  FAQ
                </Link>
              </div>
              <div className="faq-link-container">
                <GroupuiText size="small">{t('page.landingpage.faq-hint-2')}</GroupuiText>
                &nbsp;
                <GroupuiText size="small" weight="bold">
                  cis.
                </GroupuiText>
              </div>
              <GroupuiText size="small">{t('page.landingpage.faq-hint-3')}</GroupuiText>
              <div>
                <Link
                  to="#"
                  id="faq-support-container"
                  onClick={handleMailtoSupport}
                  data-testid="faq-support-container"
                >
                  <GroupuiIcon id="faq-support-icon" data-testid="faq-support-icon" name="email-24" />
                  B2Bteam@vwgroupsupply.com
                </Link>
              </div>
            </div>
          </div>
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>

      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
        <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
          <Footer />
        </GroupuiGridCol>
        <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1"></GroupuiGridCol>
      </GroupuiGridRow>
    </div>
  );
};

export default LandingPage;
