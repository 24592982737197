import { GroupuiGridCol, GroupuiGridRow } from '@sdc-wob-type-3/group-ui-react';
import React, { Fragment, useEffect, useState } from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './Imprint.css';
import { ImprintService } from './imprint-service/ImprintService';
import { useTranslation } from 'react-i18next';
import { lang } from '../header/languagemenu/LanguageUseCase';

export const IMPRINT = '/imprint';
const VOLKSWAGEN_AG = 'https://www.volkswagenag.com';
const IMPRINT_PATH = 'meta/provider-identification.html';
const LANGUAGE_TOKEN = 'i18nextLng';

type ImprintProps = { imprintService: ImprintService };

const Imprint = (props: ImprintProps) => {
  const { t } = useTranslation();

  const [imprintDocument, setImprintDocument] = useState<string>();

  useEffect(() => {
    props.imprintService
      .getHTML(lang.getLanguage())
      .then(result => {
        setImprintDocument(result);
        return;
      })
      .catch(() => {
        window.location.href = `${VOLKSWAGEN_AG}/${localStorage.getItem(LANGUAGE_TOKEN)!}/${IMPRINT_PATH}`;
      });
  }, [t]);

  return (
    <div>
      {imprintDocument && (
        <Fragment>
          <GroupuiGridRow type="fixed">
            <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
              <Header />
            </GroupuiGridCol>
          </GroupuiGridRow>

          <GroupuiGridRow type="fixed">
            <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
              <div id="content" dangerouslySetInnerHTML={{ __html: imprintDocument }} />
            </GroupuiGridCol>
          </GroupuiGridRow>

          <GroupuiGridRow type="fixed">
            <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
            <GroupuiGridCol s="12" m="10" l="10" xl="10" xxl="10">
              <Footer />
            </GroupuiGridCol>
            <GroupuiGridCol s="0" m="1" l="1" xl="1" xxl="1" />
          </GroupuiGridRow>
        </Fragment>
      )}
    </div>
  );
};

Imprint.defaultProps = {
  imprintService: new ImprintService(),
};

export default Imprint;
