import { GroupuiButton, GroupuiGridCol, GroupuiGridRow, GroupuiHeadline } from '@sdc-wob-type-3/group-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoggedoutIcon from '../../assets/LoggedoutIcon';
import environment from '../../environment';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './IdleLogoutPage.css';

export const IDLE_LOGOUT_PATH = '/idlelogout';

export function IdleLogoutPage() {
  const { t } = useTranslation();

  const login = () => {
    window.location.href = `${environment.PRIVATE_UI_URL}`;
  };

  return (
    <div id="idlelogout-container">
      <GroupuiGridRow type="fixed">
        <GroupuiGridCol s="12" m="12" l="12" xl="12" xxl="12">
          <Header />
        </GroupuiGridCol>
      </GroupuiGridRow>
      <LoggedoutIcon id="idlelogout-image" />
      <GroupuiHeadline id="idlelogout-headline" heading="h2" weight="bold" serif={false}>
        {t('page.loggedout.header-idle')}
      </GroupuiHeadline>

      <div id="idlelogout-buttons-container">
        <GroupuiButton id="idlelogout-login-button" size="m" variant="secondary" inverted={true} onClick={login}>
          {t('page.loggedout.login')}
        </GroupuiButton>
      </div>
      <Footer />
    </div>
  );
}
