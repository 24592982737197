// if no stage var is set development vars are used
const environment = {
  PRIVATE_UI_URL: 'http://localhost:3001/internal/',
  PUBLIC_BACKEND_URL: 'http://localhost:8001',
};

// this variable will be set within the docker environment during the start
// during the local start this is not set
let stage = (window as any).location.origin || 'development';

if (stage.search('/dev-cis') > 0) {
  environment.PRIVATE_UI_URL = 'https://dev-cis.vwapps.run/internal/';
  environment.PUBLIC_BACKEND_URL = 'https://dev-cis.vwapps.run/public/api';
} else if (stage.search('/pre-cis') > 0) {
  environment.PRIVATE_UI_URL = 'https://pre-cis.vwapps.run/internal/';
  environment.PUBLIC_BACKEND_URL = 'https://pre-cis.vwapps.run/public/api';
} else if (stage.search('/cis') > 0) {
  environment.PRIVATE_UI_URL = 'https://cis.vwapps.run/internal/';
  environment.PUBLIC_BACKEND_URL = 'https://cis.vwapps.run/public/api';
}

export default environment;
