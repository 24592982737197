import * as React from 'react';

function CentralIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4675 0C55.0557 0 70.935 15.8794 70.935 35.4676C70.935 44.7891 67.339 53.2707 61.4586 59.6009L85 83.1423L83.1423 85L59.6009 61.4586C53.2707 67.3391 44.7891 70.9351 35.4675 70.9351C15.8793 70.9351 0 55.0557 0 35.4676C0 15.8794 15.8793 0 35.4675 0ZM35.3017 2.46157C17.1645 2.46157 2.46136 17.1647 2.46136 35.3019C2.46136 53.4391 17.1645 68.1422 35.3017 68.1422C53.4388 68.1422 68.1419 53.4391 68.1419 35.3019C68.1419 17.1647 53.4388 2.46157 35.3017 2.46157Z"
        fill="#20607E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1969 58.5308C41.5394 56.619 42.6277 53.9684 43.4512 50.8783H53.1611C49.83 54.6886 45.3233 57.4398 40.1969 58.5308ZM17.2763 50.9022C17.2684 50.8943 17.2631 50.8863 17.2578 50.8784H27.0101C27.8309 53.9685 28.9192 56.6191 30.2617 58.5309C25.2677 57.4664 20.6974 54.8185 17.2763 50.9022ZM30.2623 11.9274C28.9277 13.8286 27.8421 16.4606 27.0186 19.5322H17.3431C20.6689 15.7483 25.1571 13.0157 30.2623 11.9274ZM53.1161 19.5323H43.4406C42.6171 16.4607 41.5315 13.8287 40.1969 11.9275C45.3021 13.0158 49.7903 15.7484 53.1161 19.5323ZM45.3021 33.839C45.22 29.83 44.8017 25.8105 44.0576 22.1802H55.147C57.3739 25.5669 58.7403 29.5547 58.9918 33.839H45.3021ZM45.3048 36.4869H58.9971C58.772 40.8056 57.4084 44.8199 55.1762 48.2304H44.0655C44.8123 44.5736 45.2306 40.5197 45.3048 36.4869ZM25.1565 33.839H11.4668C11.7184 29.5547 13.0847 25.567 15.3116 22.1803H26.401C25.6569 25.8106 25.2386 29.8301 25.1565 33.839ZM41.3482 22.1803C42.0843 25.5246 42.5662 29.4859 42.651 33.839H27.8068C27.8942 29.4859 28.3734 25.5246 29.1096 22.1803H41.3482ZM35.2292 11.3979C37.1622 11.3979 39.2169 14.4907 40.6786 19.5323H29.7798C31.2414 14.4907 33.2989 11.3979 35.2292 11.3979ZM11.4565 36.4869H25.1542C25.2283 40.5197 25.6467 44.5736 26.3934 48.2304H15.2828C13.0003 44.7325 11.6763 40.6891 11.4565 36.4869ZM29.1017 48.2303C28.3603 44.8622 27.8836 40.8718 27.8042 36.4868H42.6537C42.5742 40.8718 42.0976 44.8622 41.3562 48.2303H29.1017ZM35.2295 59.0603C33.2912 59.0603 31.2285 55.949 29.7668 50.8783H40.6921C39.2305 55.949 37.1677 59.0603 35.2295 59.0603ZM35.2295 8.75C20.6289 8.75 8.75037 20.6312 8.75037 35.2291C8.75037 41.6397 11.0699 47.8253 15.2828 52.6445C20.3164 58.4037 27.5849 61.7083 35.2295 61.7083C49.83 61.7083 61.7086 49.8297 61.7086 35.2291C61.7086 20.6312 49.83 8.75 35.2295 8.75Z"
        fill="white"
      />
      <mask id="mask0_1526_69290" maskUnits="userSpaceOnUse" x="8" y="8" width="54" height="54">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1969 58.5308C41.5394 56.619 42.6277 53.9684 43.4512 50.8783H53.1611C49.83 54.6886 45.3233 57.4398 40.1969 58.5308ZM17.2763 50.9022C17.2684 50.8943 17.2631 50.8863 17.2578 50.8784H27.0101C27.8309 53.9685 28.9192 56.6191 30.2617 58.5309C25.2677 57.4664 20.6974 54.8185 17.2763 50.9022ZM30.2623 11.9274C28.9277 13.8286 27.8421 16.4606 27.0186 19.5322H17.3431C20.6689 15.7483 25.1571 13.0157 30.2623 11.9274ZM53.1161 19.5323H43.4406C42.6171 16.4607 41.5315 13.8287 40.1969 11.9275C45.3021 13.0158 49.7903 15.7484 53.1161 19.5323ZM45.3021 33.839C45.22 29.83 44.8017 25.8105 44.0576 22.1802H55.147C57.3739 25.5669 58.7403 29.5547 58.9918 33.839H45.3021ZM45.3048 36.4869H58.9971C58.772 40.8056 57.4084 44.8199 55.1762 48.2304H44.0655C44.8123 44.5736 45.2306 40.5197 45.3048 36.4869ZM25.1565 33.839H11.4668C11.7184 29.5547 13.0847 25.567 15.3116 22.1803H26.401C25.6569 25.8106 25.2386 29.8301 25.1565 33.839ZM41.3482 22.1803C42.0843 25.5246 42.5662 29.4859 42.651 33.839H27.8068C27.8942 29.4859 28.3734 25.5246 29.1096 22.1803H41.3482ZM35.2292 11.3979C37.1622 11.3979 39.2169 14.4907 40.6786 19.5323H29.7798C31.2414 14.4907 33.2989 11.3979 35.2292 11.3979ZM11.4565 36.4869H25.1542C25.2283 40.5197 25.6467 44.5736 26.3934 48.2304H15.2828C13.0003 44.7325 11.6763 40.6891 11.4565 36.4869ZM29.1017 48.2303C28.3603 44.8622 27.8836 40.8718 27.8042 36.4868H42.6537C42.5742 40.8718 42.0976 44.8622 41.3562 48.2303H29.1017ZM35.2295 59.0603C33.2912 59.0603 31.2285 55.949 29.7668 50.8783H40.6921C39.2305 55.949 37.1677 59.0603 35.2295 59.0603ZM35.2295 8.75C20.6289 8.75 8.75037 20.6312 8.75037 35.2291C8.75037 41.6397 11.0699 47.8253 15.2828 52.6445C20.3164 58.4037 27.5849 61.7083 35.2295 61.7083C49.83 61.7083 61.7086 49.8297 61.7086 35.2291C61.7086 20.6312 49.83 8.75 35.2295 8.75Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1526_69290)"></g>
    </svg>
  );
}

export default CentralIcon;
