import * as React from 'react';

function EasyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4675 0C55.0557 0 70.935 15.8794 70.935 35.4676C70.935 44.7891 67.339 53.2707 61.4586 59.6009L85 83.1423L83.1423 85L59.6009 61.4586C53.2707 67.3391 44.7891 70.9351 35.4675 70.9351C15.8793 70.9351 0 55.0557 0 35.4676C0 15.8794 15.8793 0 35.4675 0ZM35.4675 2.62739C17.3303 2.62739 2.62723 17.3305 2.62723 35.4677C2.62723 53.6049 17.3303 68.308 35.4675 68.308C53.6047 68.308 68.3078 53.6049 68.3078 35.4677C68.3078 17.3305 53.6047 2.62739 35.4675 2.62739Z"
        fill="#20607E"
      />
      <rect x="11.5555" y="19.4991" width="45.3624" height="29.475" rx="1.85" stroke="white" strokeWidth="2.3" />
      <rect x="25.2999" y="24.3066" width="26.8101" height="1.98593" fill="white" fillOpacity="0.7" />
      <rect x="25.2999" y="28.2788" width="26.8101" height="1.98593" fill="white" fillOpacity="0.7" />
      <rect x="25.2999" y="32.2505" width="26.8101" height="1.98593" fill="white" fillOpacity="0.7" />
      <rect x="15.3703" y="38.2085" width="36.7397" height="1.98593" fill="white" fillOpacity="0.7" />
      <rect x="15.3703" y="42.1802" width="36.7397" height="1.98593" fill="white" fillOpacity="0.7" />
      <ellipse cx="19.8386" cy="26.7895" rx="2.48242" ry="2.48242" fill="#D8D8D8" />
      <path
        d="M16.3633 31.2651C16.3633 30.7129 16.811 30.2651 17.3633 30.2651H22.314C22.8663 30.2651 23.314 30.7129 23.314 31.2651V34.237H16.3633V31.2651Z"
        fill="#D8D8D8"
      />
    </svg>
  );
}

export default EasyIcon;
