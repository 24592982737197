import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import deFile from './de/de.json';
import enFile from './en/en.json';
import plFile from './pl/pl.json';
import dePrivacy from './de/privacy-de.json';
import enPrivacy from './en/privacy-en.json';
import deTermsOfUse from './de/terms-of-use-de.json';
import enTermsOfUse from './en/terms-of-use-en.json';
import deLanguagecodes from './de/languagecodes-de.json';
import enLanguagecodes from './en/languagecodes-en.json';
import plLanguagecodes from './pl/languagecodes-pl.json';
import deFAQ from './de/faq-de.json';
import enFAQ from './en/faq-en.json';
import plFAQ from './pl/faq-pl.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    returnNull: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        common: enFile,
        privacy: enPrivacy,
        termsOfUse: enTermsOfUse,
        languagecodes: enLanguagecodes,
        faq: enFAQ,
      },
      de: {
        common: deFile,
        privacy: dePrivacy,
        termsOfUse: deTermsOfUse,
        languagecodes: deLanguagecodes,
        faq: deFAQ,
      },
      pl: {
        common: plFile,
        privacy: enPrivacy,
        termsOfUse: enTermsOfUse,
        languagecodes: plLanguagecodes,
        faq: plFAQ,
      },
    },
    defaultNS: 'common',
  });

export default i18n;
