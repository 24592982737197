import { NotificationSeverity } from '@sdc-wob-type-3/group-ui';

export class CisNotification {
  public message: string;
  public severity: NotificationSeverity;
  public created: Date;

  constructor(message: string, severity: NotificationSeverity) {
    this.message = message;
    this.severity = severity;
    this.created = new Date();
  }
}
