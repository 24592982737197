import * as React from 'react';

function BookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="694" height="452" viewBox="0 0 694 452" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8.39075H601.345V429.219H1V8.39075Z"
        fill="#C6DFE7"
        stroke="#7A8084"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.348 23.3703C299.348 23.3703 352.838 11.5784 389.057 13.1302C425.276 14.6819 481.113 18.6673 504.002 23.3703C526.891 28.0733 580.92 23.3703 580.92 23.3703V422.679C580.92 422.679 486.435 427.779 450.738 422.679C415.041 417.579 394.07 421.578 372.255 422.679C350.44 423.78 301.474 414.675 301.474 414.675L299.348 23.3703Z"
        fill="white"
        stroke="#7A8084"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M408.03 8.96475L416.526 21.9061L402.76 37.3845L419.321 47.2505L405.034 57.5255L417.877 64.399L413.367 78.2822L430.642 88.9179L422.08 99.7999L442.943 103.142L430.642 111.745L447.443 117.576V128.925L438.983 142.475L397.794 167.583L419.321 180.738L397.794 197.974L402.76 204.447L390.902 217.486L397.794 225.781L414.788 233.528L422.08 239.025L411.918 253.431L402.76 260.025L417.877 277.171L414.788 291.504L405.034 308.709L420.805 312.704L429.084 324.109L417.877 334.889L405.034 344.056L396.523 356.557L402.76 363.346L390.902 378.885L405.034 382.805L389.057 403.725L402.76 409.907L389.057 437.323C389.057 437.323 449.157 421.628 492.117 429.475C535.077 437.323 526.257 434.138 555.113 440.686C583.97 447.234 585.981 451 621.776 451C657.571 451 689.688 446.883 689.688 439.684C689.688 432.485 692.766 17.2547 692.766 17.2547C692.766 17.2547 667.922 27.9489 641.895 27.9489C615.867 27.9489 574.855 26.3014 548.927 17.2547C522.999 8.20799 482.498 2.35162 454.512 2.35162C426.526 2.35162 408.03 8.96475 408.03 8.96475Z"
        fill="white"
        stroke="#7A8084"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.798 24.1253C291.798 24.1253 239.966 13.4793 209.643 13.4793C179.32 13.4793 142.892 16.3787 123.076 18.6277C103.26 20.8768 81.8621 19.7794 59.9964 22.006C38.1308 24.2327 13.9863 24.1253 13.9863 24.1253V414.945C13.9863 414.945 74.1928 414.225 94.1194 417.35C114.046 420.475 138.148 422.302 172.713 422.302C207.277 422.302 231.314 424.849 251.582 422.302C271.849 419.756 293.924 415.43 293.924 415.43L291.798 24.1253Z"
        fill="#EAF4F7"
        stroke="#7A8084"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.348 23.3703L353.965 1L345.597 24.4438L353.965 35.4948L343.781 51.6392L353.965 61.1711L345.597 72.3223L349.688 81.4501L343.781 91.8326L334.05 98.7322L327.726 107.543L332.9 119.46L343.781 128.094L338.388 142.242L335.311 156.436L345.597 178.173L338.388 183.54L332.9 195.274L337.128 201.365L349.688 215.378L353.965 226.676L345.597 235.241L349.688 243.923L334.05 255.727L340.395 264.561L349.688 274.713L337.128 284.037L321.359 293.077L316.575 306.81V317.938L327.726 324.025L319.225 332.388L332.9 338.134L327.726 348.301L339.38 362.371L335.311 375.289L343.781 385.787L335.311 391.635L347.239 406.725L337.128 419.117L343.781 435.288L301.474 414.675L299.348 23.3703Z"
        fill="white"
        stroke="#7A8084"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.348 23.3703C299.348 23.3703 248.169 12.7243 217.193 12.7243C186.217 12.7243 147.354 14.6685 130.213 16.3577C113.071 18.047 85.3399 18.6695 65.1705 20.692C45.0011 22.7145 21.5362 23.467 21.5362 23.3703C21.5362 23.2736 21.5362 414.675 21.5362 414.675C21.5362 414.675 78.9265 417.156 97.1864 419.774C115.446 422.392 152.112 423.434 183.028 423.434C213.944 423.434 238.864 424.094 259.132 421.547C279.399 419.001 301.474 414.675 301.474 414.675"
        fill="white"
      />
      <path
        d="M299.348 23.3703C299.348 23.3703 248.169 12.7243 217.193 12.7243C186.217 12.7243 147.354 14.6685 130.213 16.3577C113.071 18.047 85.3399 18.6695 65.1705 20.692C45.0011 22.7145 21.5362 23.467 21.5362 23.3703C21.5362 23.2736 21.5362 414.675 21.5362 414.675C21.5362 414.675 78.9265 417.156 97.1864 419.774C115.446 422.392 152.112 423.434 183.028 423.434C213.944 423.434 238.864 424.094 259.132 421.547C279.399 419.001 301.474 414.675 301.474 414.675"
        stroke="#7A8084"
      />
      <g clip-path="url(#clip0_2053_66755)">
        <mask id="mask0_2053_66755" maskUnits="userSpaceOnUse" x="37" y="53" width="228" height="81">
          <rect x="38.166" y="53.8817" width="226" height="79" rx="2" fill="white" stroke="white" stroke-width="0.5" />
        </mask>
        <g mask="url(#mask0_2053_66755)">
          <rect x="49.166" y="81.8817" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="69.8817" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="103.882" width="92" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="115.882" width="92" height="8" fill="#EEF0F2" />
        </g>
      </g>
      <g clip-path="url(#clip1_2053_66755)">
        <mask id="mask1_2053_66755" maskUnits="userSpaceOnUse" x="37" y="139" width="228" height="81">
          <rect x="38.166" y="139.882" width="226" height="79" rx="2" fill="white" stroke="white" stroke-width="0.5" />
        </mask>
        <g mask="url(#mask1_2053_66755)">
          <rect x="49.166" y="168.882" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="156.882" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="190.882" width="92" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="202.882" width="92" height="8" fill="#EEF0F2" />
        </g>
      </g>
      <g clip-path="url(#clip2_2053_66755)">
        <mask id="mask2_2053_66755" maskUnits="userSpaceOnUse" x="37" y="226" width="228" height="81">
          <rect x="38.166" y="226.882" width="226" height="79" rx="2" fill="white" stroke="white" stroke-width="0.5" />
        </mask>
        <g mask="url(#mask2_2053_66755)">
          <rect x="49.166" y="255.882" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="243.882" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="277.882" width="92" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="289.882" width="92" height="8" fill="#EEF0F2" />
        </g>
      </g>
      <g clip-path="url(#clip3_2053_66755)">
        <mask id="mask3_2053_66755" maskUnits="userSpaceOnUse" x="37" y="313" width="228" height="81">
          <rect x="38.166" y="313.882" width="226" height="79" rx="2" fill="white" stroke="white" stroke-width="0.5" />
        </mask>
        <g mask="url(#mask3_2053_66755)">
          <rect x="49.166" y="341.882" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="329.882" width="116" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="363.882" width="92" height="8" fill="#EEF0F2" />
          <rect x="49.166" y="375.882" width="92" height="8" fill="#EEF0F2" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2053_66755">
          <rect width="226.498" height="78.5193" fill="white" transform="translate(38.12 53.7602)" />
        </clipPath>
        <clipPath id="clip1_2053_66755">
          <rect width="226.498" height="78.5193" fill="white" transform="translate(38.12 140.584)" />
        </clipPath>
        <clipPath id="clip2_2053_66755">
          <rect width="226.498" height="78.5193" fill="white" transform="translate(38.12 227.409)" />
        </clipPath>
        <clipPath id="clip3_2053_66755">
          <rect width="226.498" height="78.5193" fill="white" transform="translate(38.12 314.233)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BookIcon;
