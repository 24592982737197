import { GroupuiButton, GroupuiHeadline, GroupuiModal, GroupuiText } from '@sdc-wob-type-3/group-ui-react';
import { useEffect } from 'react';
import './ConfirmModal.css';

type ConfirmModalProps = {
  isVisible: boolean;
  confirmQuestion: string;
  confirmText: string;
  confirmBtnText: string;
  cancelBtnText: string;
  confirm: any;
  cancel: any;
  setInvisible: any;
};
const ConfirmModal = (props: ConfirmModalProps) => {
  useEffect(() => {}, []);

  const cancel = () => {
    props.cancel();
  };
  const confirm = () => {
    props.confirm();
  };

  return (
    <GroupuiModal
      displayed={props.isVisible}
      closeButton={true}
      margin="150px 25px 0px 25px"
      background={'dark'}
      onGroupuiModalClose={() => props.setInvisible()}
      data-testid="confirm-modal"
    >
      <GroupuiHeadline className="confirm-text" heading="h4">
        {props.confirmQuestion}
      </GroupuiHeadline>

      <GroupuiText className="confirm-text">{props.confirmText}</GroupuiText>

      <div className="button-row">
        <div className="button-wrapper">
          <GroupuiButton
            alignment="center"
            type="button"
            variant="secondary"
            fullwidth={true}
            data-testid="contact-confirm-modal-cancel"
            onClick={cancel}
          >
            {props.cancelBtnText}
          </GroupuiButton>
        </div>
        <div className="button-wrapper">
          <GroupuiButton
            alignment="center"
            type="button"
            variant="secondary"
            fullwidth={true}
            data-testid="contact-confirm-modal-submit"
            onClick={confirm}
          >
            {props.confirmBtnText}
          </GroupuiButton>
        </div>
      </div>
    </GroupuiModal>
  );
};

ConfirmModal.defaultProps = {
  confirmText: '',
};

export default ConfirmModal;
