import * as React from 'react';

function LoggedoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.008 41.6668H116.658V33.3335H146.175C148.475 33.3335 150.342 35.2002 150.342 37.5002V162.5C150.342 164.8 148.475 166.667 146.175 166.667H117.008V158.333H142.008V41.6668ZM57.7291 104.1L64.7458 111.117L58.8541 117.008L47.5541 105.717V105.725L41.6625 99.8335V99.8252L47.5541 93.9252L47.5625 93.9335L58.6791 82.8168L64.5708 88.7168L57.5125 95.7668L125.296 95.8335V104.167L57.7291 104.1Z"
        fill="white"
      />
      <mask id="mask0_43_47095" maskUnits="userSpaceOnUse" x="41" y="33" width="110" height="134">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.008 41.6668H116.658V33.3335H146.175C148.475 33.3335 150.342 35.2002 150.342 37.5002V162.5C150.342 164.8 148.475 166.667 146.175 166.667H117.008V158.333H142.008V41.6668ZM57.7291 104.1L64.7458 111.117L58.8541 117.008L47.5541 105.717V105.725L41.6625 99.8335V99.8252L47.5541 93.9252L47.5625 93.9335L58.6791 82.8168L64.5708 88.7168L57.5125 95.7668L125.296 95.8335V104.167L57.7291 104.1Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_43_47095)"></g>
    </svg>
  );
}

export default LoggedoutIcon;
